import React from "react";

import { Box, Typography } from "@mui/material";
import { SectionContainer } from "@product-site-frontend/shared";

import ConsultationForm from "../../components/ConsultationForm";

export default function SectionConsultation() {
  return (
    <Box id={"section_consultation"} sx={{ mt: 8, backgroundColor: "#f8f8f9" }}>
      <SectionContainer>
        <Box display="flex" flexWrap="wrap">
          <Box mr={{ lg: 8 }} width={{ xs: 1, lg: 0.5 }}>
            <Typography component="div" textAlign="left !important" variant="h2">
              Хотите воспользоваться лизингом, но&nbsp;не&nbsp;знаете, с&nbsp;чего начать?
            </Typography>
            <Typography color="#373737" fontWeight={500} mb={5} mt={3} variant="body1">
              Наша команда с&nbsp;радостью ответит на&nbsp;ваши вопросы!
              <br />
              Заполните форму, и&nbsp;мы свяжемся с вами в&nbsp;ближайшее время.
            </Typography>
          </Box>
          <Box width={{ xs: 1, lg: 0.35 }}>
            <ConsultationForm />
          </Box>
        </Box>
      </SectionContainer>
    </Box>
  );
}
