import React from 'react';

import {Button} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SectionContainer } from '@product-site-frontend/shared';
import { Link as RouterLink } from 'gatsby';

import image404 from '../assets/images/404@3x.png';
import image404Man from '../assets/images/404-man@3x.png';

const Base404Page = () =>  {
  return (
    <SectionContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          my: '50px',
        }}
      >
        <Box maxWidth="455px" >
          <Box alt="404" component="img" src={image404} sx={{ mb: 5 }} />
          <Box
            sx={{
              maxWidth: { xs: '100%', md: '350px' },
              position: 'relative',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Typography
              component="h1"
              sx={{
                mb: 2,
                color: '#f60',
                fontSize: '22px',
                fontWeight: '900',
              }}
              variant="caption"
            >
              Такой страницы нет
            </Typography>
            <Typography
              color="textSecondary"
              component="h2"
              sx={{
                mb: 4,
                fontSize: '18px',
                fontWeight: '500',
                lineHeight: '1.39'
              }}
              variant="caption"
            >
              Это значит, что запрашиваемая вами страница была удалена,{' '}
              перемещена или вы просто набрали неточный адрес
            </Typography>
            <Box
              alt="404"
              component="img"
              src={image404Man}
              sx={{
                display: { xs: 'none', md: 'block' },
                position: 'absolute',
                bottom: 0,
                right: -155,
                width: 120,
              }}
            />
          </Box>
        </Box>

        <Button component={RouterLink} size="large" to='/' variant="contained">
          Перейти на главную
        </Button>
      </Box>
    </SectionContainer>
  );
};

export default Base404Page;