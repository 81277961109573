/* eslint-disable react/jsx-filename-extension */
import React from "react";
import ReactDOM from "react-dom";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "whatwg-fetch";
import LayoutMedicineThemeProvider from "./src/layouts/LayoutMedicineThemeProvider";
import LayoutMainThemeProvider from "./src/layouts/LayoutMainThemeProvider";

const RE_CAPTCHA_KEY = "6Lc85uMZAAAAAGWrY22hwPippm6AeqczqpHwW--c";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export function wrapRootElement({ element }) {
  return <GoogleReCaptchaProvider reCaptchaKey={RE_CAPTCHA_KEY}>{element}</GoogleReCaptchaProvider>;
}

export function wrapPageElement({ element, props }) {
  if (props.uri.includes("lizing-meditsinskogo-oborudovaniya")) {
    return <LayoutMedicineThemeProvider {...props}>{element}</LayoutMedicineThemeProvider>;
  }

  return <LayoutMainThemeProvider {...props}>{element}</LayoutMainThemeProvider>;
}
