import React, { useEffect, useRef } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useLocation } from '@reach/router';
import qs from "querystringify";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import useFetch from 'use-http';
import * as yup from 'yup';

import getAppTrackingParams from '../utils/getTrackingParams';
import FormTextField from './FormTextField';
import PhoneMaskInput from './PhoneMaskInput';

const schema = yup.object({
  name: yup
    .string()
    .required('Укажите фамилию, имя и отчество через пробел')
    .max(256, 'Укажите фамилию, имя и отчество через пробел')
    .min(2, 'Укажите фамилию, имя и отчество через пробел'),
  phone: yup
    .string()
    .required('Укажите номер телефона')
    .matches(/(^\+7\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}$)/, {
      message: 'Номер телефона должен состоять из 10 цифр',
    }),
});

const CREATE_CONSULTATION_REQUEST =
  `${process.env.GATSBY_FRONTOFFICE_API}/consultations`;

export default function ConsultationForm() {
  const location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const utm_source = useRef(null);
  const utm_full = useRef(null);

  useEffect(() => {
    if (location.search.includes('utm_source')) {
      let urlParam = qs.parse(location.search);

      utm_source.current = urlParam.utm_source;
      utm_full.current = location.href;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const methods = useForm({
    mode: 'onTouched',
    // criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: {
      // name: 'Петров Иван',
      // phone: '+70902567903',
    },
    resolver: yupResolver(schema),
  });

  const { control, formState, getValues, handleSubmit, setError } = methods;

  const { post, response } = useFetch(CREATE_CONSULTATION_REQUEST, {
    cachePolicy: 'no-cache',
    retries: 3,
    retryDelay: ({ attempt }) => Math.min(
      attempt > 0
        ? 2 ** attempt * 1000
        : 1000, 30 * 1000,
    ),
    async retryOn({ error, response }) {
      // retry on any network error, or 5xx status codes
      if (error || response?.status >= 500) {
        return true;
      }
    },
    interceptors: {
      request: async ({ options }) => {
        try {
          const token = await executeRecaptcha('landing_consult_form');
          options.body.append('g-recaptcha-response', token);
        } catch (error) {
          console.error('Interceptors request', error);
        }

        return options;
      },
    },
    onError: ({ error }) => {
      const values = getValues();
      console.error(
        'Серверная ошибка при отправке заявки на консультацию',
        values,
        error,
      );

      if (error.name === '400') {
        Object.entries(response.data.errors).forEach(
          ([fieldName, [message]]) => setError(fieldName, { message }),
        );
      } else {
        setError('FORM_ERROR', { message: error.message });
      }
    },
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'send_form_main',
          ea: 'sent_form',
          ec: 'zayavka_cons',
        });
      } else {
        console.error('dataLayer не найден');
      }

      if (window.ga) {
        window.ga('send', 'event', 'button', 'zayavka_cons', '');
      } else {
        console.error('GA не найден');
      }
    }
  }, [formState.isSubmitSuccessful]);

  async function onSubmit(data) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('phone', data.phone);
    formData.append('roistat_id', window?.roistat?.visit);

    const trackingParams = getAppTrackingParams(location);
    Object.entries(trackingParams).forEach(([key, value]) => {
      if (utm_source.current === 'avito' && key === 'channel') {
        formData.append('channel', utm_source.current);
      } else if (utm_source.current === 'avito' && key === 'landing_url') {
        formData.append('landing_url', utm_full.current);
      } else {
        formData.append(key, value);
      }
    });

    try {
      await post(formData);
    } catch (error) {
      setError('FORM_ERROR', { message: error?.message });
    }
  }

  if (formState.isSubmitSuccessful) {
    return (
      <Box display="flex" flexDirection="column" p={4}>
        <Box color="success.main" my={5}>
          <Typography align="center" variant="h3">
            Мы скоро Вам перезвоним!
          </Typography>
        </Box>
        <Box mb={3} />
      </Box>
    );
  }

  return (
    <Box
      autoCapitalize="off"
      autoCorrect="off"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spellCheck="false"
      sx={{ mb: 1 }}
    >
      <FormTextField
        InputLabelProps={{ htmlFor: 'form-consultation-name' }}
        control={control}
        id="form-consultation-name"
        label="Имя"
        name="name"
        placeholder="Иванов Михаил Валентинович"
        sx={{ mb: 1 }}
        variant="filled"
      />
      <FormTextField
        InputLabelProps={{ htmlFor: 'form-consultation-phone' }}
        InputProps={{ inputComponent: PhoneMaskInput }}
        control={control}
        id="form-consultation-phone"
        label="Контактный телефон"
        name="phone"
        placeholder="+7 (***) ***-**-**"
        sx={{ mb: 1 }}
        type="tel"
        variant="filled"
      />
      {formState.errors['FORM_ERROR'] && (
        <Alert severity="error">
          <AlertTitle>
            <Box mt={1}>Произошла ошибка при отправке заявки</Box>
          </AlertTitle>
          <Typography variant="body2">
            Мы уже знаем об этом и скоро исправим
          </Typography>
        </Alert>
      )}
      <Box my={4}>
        <LoadingButton
          color="primary"
          disabled={!!formState.errors['FORM_ERROR']}
          fullWidth
          loading={formState.isSubmitting}
          size="large"
          type="submit"
          variant="contained"
        >
          Отправить
        </LoadingButton>
      </Box>
      <Typography variant="caption">
        Отправляя форму, вы даете согласие на обработку{' '}
        <Typography
          component="a"
          href="https://public-documents.arenza.ru/политика_обработки_персональных_данных.pdf"
          variant="inherit"
        >
          персональных данных
        </Typography>
        , указанных вами в заявке, в соответствии с требованиями Федерального закона{' '}
        <Typography component="span" noWrap variant="inherit">
          № 152-ФЗ
        </Typography>{' '}
        от 27.07.2006 «О персональных данных».
      </Typography>
    </Box>
  );
}
