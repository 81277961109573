import {
  FormFieldAutocompleteWrapper,
  FormFieldUploadWrapper,
  FormFieldTextWrapper,
  FormFieldRadioWrapper,
  FormFieldAutocompleteNameWrapper,
  FormFieldCheckboxWrapper,
  FormFieldCheckboxGroupWrapper,
  FormFieldAutocompleteLegalEntityWrapper,
} from "../FormFieldWrappers";

const defaultMapping = {
  text: FormFieldTextWrapper,
  radio: FormFieldRadioWrapper,
  checkbox: FormFieldCheckboxWrapper,
  checkboxGroup: FormFieldCheckboxGroupWrapper,
  nameAutocomplete: FormFieldAutocompleteNameWrapper,
  legalEntityAutocomplete: FormFieldAutocompleteLegalEntityWrapper,
  file: FormFieldUploadWrapper,
  autocomplete: FormFieldAutocompleteWrapper,
};

export default defaultMapping;