import PropTypes from "prop-types";
import React from "react";

import { Button, Typography, Box } from "@mui/material";
import imageVkIcon from '../assets/images/vk_video_icon.png'

ButtonYouTube.propTypes = {
  label: PropTypes.node,
};

export default function ButtonYouTube({ label, ...props }) {
  return (
    <Button
      fullWidth
      size="large"
      sx={{
        textTransform: "none",
        textAlign: "left",
        padding: "8px 12px",
        color: "text.primary",
      }}
      variant="text"
      {...props}
    >
      <Box
        alt="vk video"
        component="img"
        src={imageVkIcon}
        sx={{
          display: 'block',
          width: 26,
        }}
      />
      <Typography sx={{ lineHeight: 1.3, ml: "12px" }} variant="body1">
        {label}
      </Typography>
    </Button>
  );
}
