import PropTypes from 'prop-types';
import React from 'react';

import { Box, Container, Typography } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';

import WhatsAppLogo from '../assets/images/whatsapp.svg';
import ExternalLink from '../components/ExternalLink';
import VKontakteLogo from '../components/VKontakteLogo';
import TelegramLogo from '../components/TelegramLogo';
import PulsLogo from '../components/PulsLogo';
import { AzLogo } from "@Development/ui-kit";

const iconSize = { width: 32, height: 32 };
const TITLE_TO_SOCIAL_LOGO = {
  Вконтакте: <VKontakteLogo style={iconSize} />,
  YouTube: <YouTubeIcon style={iconSize} />,
  Telegram: <TelegramLogo style={iconSize} />,
  Пульс: <PulsLogo style={iconSize} />
};

Footer.propTypes = {
  content: PropTypes.shape({
    aboutCompany: PropTypes.any,
    companyAddress: PropTypes.any,
    companyDetails: PropTypes.any,
    companyEmail: PropTypes.any,
    eSignatureAgreementLink: PropTypes.shape({
      text: PropTypes.any,
      url: PropTypes.any,
    }),
    icon: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.any,
        fluid: PropTypes.any,
      }),
    }),
    privacyPolicyLink: PropTypes.shape({
      text: PropTypes.any,
      url: PropTypes.any,
    }),
    social: PropTypes.array,
  }),
};

export default function Footer({ content }) {
  return (
      <Box bgcolor="#232D3E" color="white" component="footer" py={6}>
        <Container maxWidth="lg">
          <Box
              alignItems="center"
              display={{ sm: 'flex' }}
              justifyContent="space-between"
          >
            <Box flexShrink={0} height={40} width={134}>
              <AzLogo svg={{ width: 134, height: 38 }} type="basic-white" />
            </Box>
            <ExternalLink
                href={`${process.env.GATSBY_ROOT_URL}/partners/`}
                mt={{ xs: 3, sm: "unset" }}
            >
              Как стать партнером?
            </ExternalLink>
          </Box>
          <Box bgcolor="white" height="1px" my={5} width={1} />
          <Box display="flex" flexWrap="wrap">
            <Box py={3} width={{ xs: 1, md: 0.5, lg: 0.45 }}>
              <Box sx={{ mb: 3 }}>
                <Typography
                    component={ExternalLink}
                    href={`${process.env.GATSBY_ROOT_URL}`}
                    variant="subtitle1"
                >
                  Аренза.Оборудование
                </Typography>
              </Box>
              <ExternalLink
                  href={`${process.env.GATSBY_ROOT_URL}/lizing-auto/`}
                  mb={3}
                  title="Лизинг авто"
              >
                Аренза.Авто
              </ExternalLink>
              <br />
              <ExternalLink
                  href={`${process.env.GATSBY_ROOT_URL}/lizing-meditsinskogo-oborudovaniya/`}
                  mb={3}
                  title="Лизинг медицинского оборудования"
              >
                Аренза.Медицина
              </ExternalLink>
              <br />
              <ExternalLink
                  href={`${process.env.GATSBY_ROOT_URL}/shiny-v-lizing/`}
                  mb={3}
                  title="Лизинг шин"
              >
                Аренза.Шины
              </ExternalLink>
              <br />

              <Typography py={3} sx={{ mb: 3, paddingBottom: 0 }} variant="subtitle1" component="div">
                Магазин Б/У оборудования
              </Typography>
              <ExternalLink
                  href={`${process.env.GATSBY_SHOP_URL}/`}
                  mb={3}
                  title="Аренза.Renew"
              >
                Аренза.Renew
              </ExternalLink>
              <br />
            </Box>
            <Box py={3} width={{ xs: 1, md: 0.5, lg: 0.2 }}>
              <Typography sx={{ mb: 3 }} variant="subtitle1" component="div">
                О компании
              </Typography>
              <ExternalLink
                  href={`${process.env.GATSBY_ROOT_URL}/about/`}
                  mb={3}
                  target="_self"
              >
                О нас
              </ExternalLink>
              <br />
              <ExternalLink
                  href={`${process.env.GATSBY_ROOT_URL}/faqs/`}
                  mb={3}
                  target="_self"
              >
                Частые вопросы
              </ExternalLink>
              <br />
              <ExternalLink
                  href={`${process.env.GATSBY_ROOT_URL}/blog/`}
                  mb={3}
                  target="_self"
              >
                Блог
              </ExternalLink>
              <br />
              <ExternalLink
                  href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/tarrifs.pdf`}
                  mb={3}
              >
                Тарифы
              </ExternalLink>
            </Box>
            <Box py={3} width={{ xs: 1, md: 1, lg: 0.35 }}>
              <Typography component="div" sx={{ mb: '3px' }} variant="subtitle1">
                Контакты
              </Typography>

              <ExternalLink href="tel:+74951254344" mb={3} target="_self">
                +7 495 125 4344
              </ExternalLink>

              <ExternalLink
                  href="https://wa.me/+74951254344"
                  mb={3}
                  rel="nofollow noreferrer"
                  sx={{ position: 'relative', top: '11px', left: '9px' }}
                  target="_self"
              >
                <Box component={'img'} src={WhatsAppLogo} />
              </ExternalLink>

              <br />
              <ExternalLink href="tel:88007757685" mb={3} target="_self">
                8 800 775 7685
              </ExternalLink>
              <br />
              <ExternalLink href="mailto:info@arenza.ru" target="_self">
                {content?.companyEmail}
              </ExternalLink>
              <Typography sx={{ my: 2 }} variant="subtitle1" component="div">
                Режим работы с 9:00 до 18:00
              </Typography>
              <Typography variant="body2">{content?.companyAddress}</Typography>
            </Box>
          </Box>
          <Box alignItems="center" display="flex" my={4}>
            <Box bgcolor="white" height="1px" my={5} width={1} />
            <Box display="flex" flexShrink={0} mx={7}>
              {content?.social?.map(({ title, url }, i) => (
                  <Box
                      component="a"
                      href={url}
                      key={title}
                      ml={i ? 5 : 0}
                      title={title}
                      rel="nofollow"
                  >
                    {TITLE_TO_SOCIAL_LOGO[title]}
                  </Box>
              ))}
            </Box>
            <Box bgcolor="white" height="1px" my={5} width={1} />
          </Box>
          <Box display={{ lg: "flex" }}>
            <Box mb={{ xs: 4, lg: 0 }} mr={8} width={{ xs: 1, lg: 0.6 }}>
              <Typography color="rgb(255 255 255 / 54%)" variant="body2">
                {content?.companyDetails}
                <br />
                <br />
                {content?.aboutCompany}
              </Typography>
            </Box>
            <Box width={{ xs: 1, lg: 0.4 }}>
              <ExternalLink
                  component="a"
                  display="inline-block"
                  href={content?.eSignatureAgreementLink.url}
                  lineHeight={1.2}
                  mb={2}
                  py={1}
                  variant="overline"
              >
                {content?.eSignatureAgreementLink.text}
              </ExternalLink>
              <br />
              <ExternalLink
                  component="a"
                  href={content?.privacyPolicyLink.url}
                  lineHeight={1.2}
                  py={1}
                  variant="overline"
              >
                {content?.privacyPolicyLink.text}
              </ExternalLink>
            </Box>
          </Box>
        </Container>
      </Box>
  );
}
