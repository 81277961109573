/* eslint-disable react/jsx-filename-extension */
import {
  EMAIL_VALIDATION,
  FIO_VALIDATION,
  PHONE_VALIDATION,
} from '../FormEntityQuestionnaire/constants/validations';
import AmountMaskInput from '../AmountMaskInput';
import FormFieldContainer from '../FormFieldContainer';
import PhoneMaskInput from '../PhoneMaskInput';

import * as yup from 'yup';

// Сроки к количеству заявок.

// не указано => 24893,
// 18=>2135,
// 12=>2043,
//  6=>333,
//  9=>178,
// 24=>189
// 36=>16,
// 15=>10,
// все остальные меньше 10.
// 6, 12, 18, 24, 36
// 6 мес, 1 год, 1,5 года, 2 года, 3 года

// Аванс к количеству заявок.

//      0.0=>25452
//      20.0=>1485
//      10.0=>565
//      17.0=>383
//      30.0=>170
//      25.0=>113
//      27.0=>93
//      16.666666666666668=>72
//      15.0=>69
//      5.0=>42
//      40.0=>31
//      50.0=>25
//      49.0=>24

// 10, 20, 30, 40, 49

export const APPLICATION_FIELDS_CONFIG = {
  full_name: {
    name: 'full_name',
    validation: FIO_VALIDATION.required('Укажите фамилию, имя и отчество через пробел'),
    defaultValue: '',
    FieldComponent: FormFieldContainer,
    componentProps: {
      fieldType: 'nameAutocomplete',
      placeholder: 'Фёдоров Михаил Сергеевич',
      label: 'Фамилия Имя Отчество',
    },
  },
  inn: {
    name: 'inn',
    validation: yup
      .string()
      .nullable()
      .required('Укажите организацию или ИП'),
    FieldComponent: FormFieldContainer,
    componentProps: {
      label: 'Название компании, ИП или ИНН',
      fieldType: 'legalEntityAutocomplete',
    },
  },
  phone: {
    name: 'phone',
    validation: PHONE_VALIDATION.required('Укажите номер телефона'),
    defaultValue: '',
    FieldComponent: FormFieldContainer,
    componentProps: {
      label: 'Мобильный телефон',
      InputProps: {
        inputComponent: PhoneMaskInput,
      },
      fieldType: 'text',
      placeholder: '+7 (***) ***-**-**',
      type: 'tel',
    },
  },
  email: {
    name: 'email',
    validation: EMAIL_VALIDATION.required('Укажите адрес электронной почты'),
    defaultValue: '',
    FieldComponent: FormFieldContainer,
    componentProps: {
      fieldType: 'text',
      name: 'email',
      placeholder: 'fedorov@example.ru',
      label: 'Электронная почта',
    },
  },
  amount: {
    name: 'amount',
    validation: yup.string().required('Укажите стоимость предмета лизинга'),
    defaultValue: '',
    FieldComponent: FormFieldContainer,
    componentProps: {
      InputProps: {
        inputComponent: AmountMaskInput,
      },
      label: 'Стоимость предмета лизинга',
      fieldType: 'text',
      placeholder: '500 000 руб.',
      inputProps: {
        inputMode: 'numeric',
        pattern: '[0-9]*',
      },
    },
  },
  promo_code: {
    name: 'promo_code',
    validation: yup.string().notRequired(''),
    defaultValue: '',
    FieldComponent: FormFieldContainer,
    componentProps: {
      fieldType: 'text',
      placeholder: '',
      label: 'Промокод при наличии',
    },
  },
  attachments: {
    name: 'attachments',
    defaultValue: [],
    FieldComponent: FormFieldContainer,
    componentProps: {
      label: 'Коммерческое предложение',
      fieldType: 'file',
    },
  },
  subject_classificator: {
    name: 'subject_classificator',
    defaultValue: null,
    FieldComponent: FormFieldContainer,
    componentProps: {
      getOptionLabel: option => option.name,
      label: 'Категория оборудования',
      fieldType: 'autocomplete',
    },
  },
};
