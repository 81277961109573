import React from "react";

import { Box, Typography, Link } from "@mui/material";

import iconArenzaLogo from "../../assets/svg/arenza.svg";

export default function PageTechnicalWork() {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: '0 20%' }}>
      <Box alt="Логотип Arenza" component="img" src={iconArenzaLogo} sx={{ maxWidth: '300px', marginBottom: '60px', width: '100%', minWidth: '100px' }} />

      <Typography sx={{ fontSize: '40px', fontWeight: 600, marginBottom: '40px', textAlign: 'center' }}>Уважаемые пользователи!</Typography>

      <Box sx={{ textAlign: 'center' }}>
        В данный момент мы проводим на сайте технические работы, мы хотим сделать ваше времяпровождение на сайте более удобным и интересным! Для того, чтобы задать вопрос или оставить заявку на лизинг, свяжитесь с нами по номеру: <Link href={`tel:+74951254344`} sx={{ fontWeight: 600, whiteSpace: 'nowrap' }} underline="none">+7 495 125 4344</Link>
      </Box>

      <Typography sx={{ fontSize: '20px', fontWeight: 600, marginTop: '40px', textAlign: 'center' }}>С наилучшими пожеланиями, Команда Arenza</Typography>
    </Box>
  );
}
