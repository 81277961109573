exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lizing-auto-jsx": () => import("./../../../src/pages/lizing-auto.jsx" /* webpackChunkName: "component---src-pages-lizing-auto-jsx" */),
  "component---src-pages-lizing-meditsinskogo-oborudovaniya-jsx": () => import("./../../../src/pages/lizing-meditsinskogo-oborudovaniya.jsx" /* webpackChunkName: "component---src-pages-lizing-meditsinskogo-oborudovaniya-jsx" */),
  "component---src-pages-marketplace-components-marketplace-slider-index-jsx": () => import("./../../../src/pages/marketplace/components/marketplaceSlider/index.jsx" /* webpackChunkName: "component---src-pages-marketplace-components-marketplace-slider-index-jsx" */),
  "component---src-pages-marketplace-index-jsx": () => import("./../../../src/pages/marketplace/index.jsx" /* webpackChunkName: "component---src-pages-marketplace-index-jsx" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-pages-promotions-jsx": () => import("./../../../src/pages/promotions.jsx" /* webpackChunkName: "component---src-pages-promotions-jsx" */),
  "component---src-pages-shiny-v-lizing-jsx": () => import("./../../../src/pages/shiny-v-lizing.jsx" /* webpackChunkName: "component---src-pages-shiny-v-lizing-jsx" */),
  "component---src-templates-marketplace-single-jsx": () => import("./../../../src/templates/marketplace/single.jsx" /* webpackChunkName: "component---src-templates-marketplace-single-jsx" */),
  "component---src-templates-single-blog-jsx": () => import("./../../../src/templates/single-blog.jsx" /* webpackChunkName: "component---src-templates-single-blog-jsx" */),
  "component---src-templates-single-promotion-jsx": () => import("./../../../src/templates/single-promotion.jsx" /* webpackChunkName: "component---src-templates-single-promotion-jsx" */)
}

