import PropTypes from "prop-types";
import React from "react";

import { Autocomplete, Box, TextField, Typography } from "@mui/material";

FormFieldAutocompleteWrapper.propTypes = {
  field: PropTypes.any,
  fieldState: PropTypes.any,
  formState: PropTypes.any,
  helperText: PropTypes.any,
  label: PropTypes.node,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
};
export default function FormFieldAutocompleteWrapper({
  options = [],
  field: { onChange: _onChange, ref, value = null },
  fieldState: { error, invalid },
  formState,
  helperText,
  ...props
}) {
  function handleChange(event, value) {
    _onChange(value);
  }

  return (
    <Autocomplete
      {...props}
      getOptionLabel={option => option.name}
      // TODO: сделать что-то с компонентом в стилях PaperComponent={props => <Paper {...props} elevation={10} variant="outlined" />}
      onChange={handleChange}
      options={options}
      renderInput={params => (
        <TextField {...params} inputRef={ref} label="Категория оборудования" variant="filled" />
      )}
      renderOption={(props, option) => {
        return (
          <Box {...props} component="li" key={option.id}>
            <Typography noWrap sx={{ ...(option.parent_id ? { pl: 3 } : {}) }}>
              {option.name}
            </Typography>
          </Box>
        );
      }}
      sx={{ position: "relative" }}
      value={value}
    />
  );
}
