import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import FormApplication from '../components/FormApplication';
import { ApplicationContext } from '@product-site-frontend/shared';
import DialogApplicationCreate from '../components/DialogApplicationCreate';
import { navigate } from 'gatsby';

LayoutApplicationContext.propTypes = {
  children: PropTypes.node,
};

export default function LayoutApplicationContext({ children }) {
  const [applicationOpen, setApplicationOpen] = useState(false);
  const [amount, setAmount] = useState('');

  const contextValue = {
    data: {
      amount,
      applicationOpen,
      inn: '',
      phone: '',
      full_name: '',
      email: '',
      promo_code: '',
    },
    setApplicationOpen: (isOpen) => {setApplicationOpen(isOpen)},
    setAmount: (amnt) => {setAmount(amnt)},
  };

  const closeApplicationDialog = useCallback(
    () => setApplicationOpen(false),
    [setApplicationOpen],
  );

  const handleSuccess = useCallback((submitResponse) => {
    navigate(`${process.env.GATSBY_ACCOUNT_URL}/?auth_token=${submitResponse.auth_token}`);
  }, []);

  return (
    <ApplicationContext.Provider value={contextValue}>
      {children}
      <DialogApplicationCreate
        content={
          <FormApplication onSuccess={handleSuccess} url={process.env.GATSBY_FRONTOFFICE_API} />
        }
        onClose={closeApplicationDialog}
        open={applicationOpen}
        title="Заявка на лизинг"
      />
    </ApplicationContext.Provider>
  );
}
