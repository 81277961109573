import PropTypes from "prop-types";
import React from "react";

import { Box, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useDropzone } from "react-dropzone";

FormFieldUploadWrapper.propTypes = {
  onChange: PropTypes.func,
};
export default function FormFieldUploadWrapper({ onChange }) {
  const { acceptedFiles, getInputProps } = useDropzone({
    multiple: true,
    onDropAccepted: files => {
      onChange(files);
    },
  });

  const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>);

  return (
    <div>
      <input
        {...getInputProps({
          //   className: classes.input,
          id: "application-form-upload-button",
        })}
      />
      <Box component="label" htmlFor="application-form-upload-button">
        <Box
          role="button"
          sx={{
            cursor: "pointer",
            userSelect: "none",
            display: "inline-flex",
            color: "secondary.main",
            alignItems: "center",
          }}
        >
          <Typography component="span" sx={{ fontWeight: 500 }}>
            Прикрепить файлы
          </Typography>
          <Box mr={2}>
            <AttachFileIcon />
          </Box>
        </Box>
      </Box>
      <Box component="ul">{files}</Box>
    </div>
  );
}
