import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { Button } from "@mui/material";

WrapperButton.propTypes = {
  children: PropTypes.any,
  colorScheme: PropTypes.oneOf([
    "grey",
    "orange",
    "white",
    "orangeStroke",
    "greyStrokeRounded"
  ]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  sx: PropTypes.object,
};

export default function WrapperButton({ children, colorScheme, disabled, onClick: onClickProps, sx, ...props }) {
  const styles = useMemo(() => ({
    button: {
      color: '#ffffff',
      background: '#ee8035',
      fontSize: '16px',
      fontWeight: 500,
      textTransform: 'initial',
      padding: '14px 20px',
      display: 'inline-flex',
      gap: '12px',
      minWidth: '0',
      whiteSpace: 'nowrap',

      '& path': {
        fill: '#fff',
      },

      '& .MuiTouchRipple-root': {
        display: 'none'
      },
      '&:hover': {
        background: '#d1712e',
      },
      '&:active': {
        background: '#f09249',
      },
      '&:disabled': {
        background: '#f8d8bb',
        color: '#ffffff',

        '& path': {
          fill: '#fff',
        },
      },

      '&.grey': {
        color: '#6E7884',
        background: '#f3f4f5',

        '& path': {
          fill: '#6E7884',
        },

        '&:hover': {
          background: '#fdf2e8',
          color: '#ee8035',

          '& path': {
            fill: '#ee8035',
          },
        },
        '&:active': {
          background: '#fbe5d2',
          color: '#d1702e',

          '& path': {
            fill: '#d1702e',
          },
        },
        '&:disabled': {
          background: '#f9fafa',
          color: '#d4d6da',

          '& path': {
            fill: '#d4d6da',
          },
        }
      },

      '&.white': {
        color: '#6E7884',
        background: '#ffffff',
        border: '1px solid #cfd2d6',

        '& path': {
          fill: '#b7bbc1',
        },

        '&:hover': {
          background: '#fdf4ed',
          color: '#ee8035',
          border: '1px solid #ee8035',

          '& path': {
            fill: '#ee8035',
          },
        },
        '&:active': {
          background: '#ffffff',
          color: '#707783',
          border: '1px solid #707883',

          '& path': {
            fill: '#707783',
          },
        },
        '&:disabled': {
          background: '#ffffff',
          color: '#d0d2d6',
          border: '1px solid #cfd2d6',

          '& path': {
            fill: '#d0d2d6',
          },
        }
      },

      '&.orangeStroke': {
        background: '#fff',
        color: '#FF780F',
        border: '1px solid #FF780F',

        '& path': {
          fill: '#ee8035',
        },

        '&:hover': {
          background: '#fdf4ed',
          color: '#FF780F',
          border: '1px solid #f2a565',

          '& path': {
            fill: '#f19b4c',
          },
        },
        '&:active': {
          background: '#fcefe3',
          color: '#D9660D',
          border: '1px solid #ca6d2c',

          '& path': {
            fill: '#D9660D',
          },
        },
        '&:disabled': {
          background: '#ffffff',
          color: '#f9d8bb',
          border: '1px solid #fcecdd',

          '& path': {
            fill: '#f9d8bb',
          },
        }
      },

      '&.greyStrokeRounded': {
        background: '#fff',
        color: '#6E7884',
        border: '1px solid #CFD2D6',
        borderRadius: '32px',
        padding: '10px 24px',

        '& path': {
          fill: '#6E7884',
        },

        '&:hover': {
          background: '#fdf4ed',
          color: '#838c97',
          border: '1px solid #d6d9dc',

          '& path': {
            fill: '#838c97',
          },
        },
        '&:active': {
          background: '#fff',
          color: '#838c97',
          border: '1px solid #d6d9dc',

          '& path': {
            fill: '#838c97',
          },
        },
        '&:disabled': {
          background: '#fff',
          color: '#919aa4',
          border: '1px solid #dbdde0',

          '& path': {
            fill: '#919aa4',
          },
        }
      },

      ...sx,
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const onClick = () => {
    if (onClickProps && typeof onClickProps === 'function') {
      onClickProps();
    }
  }

  return (
    <Button
      className={colorScheme}
      disabled={disabled}
      onClick={onClick}
      sx={styles.button}
      {...props}
    >
      {children}
    </Button>
  );
}
