import PropTypes from 'prop-types';
import React from 'react';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import _noop from 'lodash.noop';

FormFieldRadioWrapper.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.object,
  fieldState: PropTypes.object,
  formState: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.node,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
};
export default function FormFieldRadioWrapper({
  disabled = false,
  field: { ref, value },
  fieldState: { error, invalid, isTouched },
  formState,
  helperText,
  label,
  onBlur = _noop,
  onChange = _noop,
  options = [],
}) {
  // const showError = !!(isTouched && invalid);
  const showError = !!invalid;

  return (
    <FormControl
      component="fieldset"
      error={showError}
      fullWidth
      margin="normal"
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup onBlur={onBlur} onChange={onChange} row value={value}>
        {options.map((opt) => (
          <FormControlLabel
            control={<Radio />}
            disabled={disabled}
            inputRef={ref}
            key={opt.label}
            label={opt.label}
            sx={{ mr: 8 }}
            value={opt.value}
          />
        ))}
      </RadioGroup>
      <FormHelperText variant="standard">
        {(showError && error?.message) || helperText}
      </FormHelperText>
    </FormControl>
  );
}
