import { createContext } from 'react';

const defaultContextValue = {
  data: {
    amount: '',
    applicationOpen: false,
    inn: '',
    phone: '',
    full_name: '',
    email: '',
    promo_code: '',
  },
  setAmount: (amnt) => {},
  setApplicationOpen: (isOpen) => {},
};

const ApplicationContext = createContext(defaultContextValue);

export default ApplicationContext;
