import { orange } from "./colors";

export const palette = {
  primary: {
    main: orange,
    contrastText: "#fff",
    dark: `#ec6608`,
    light: "#ffaa12",
  },
  secondary: {
    main: `#2484c6`,
  },
  error: {
    main: "#d32f2f",
  },
  background: {
    default: `#fff`,
  },
  text: {
    primary: "#0D1D32",
    secondary: "#7d7d7d",
  },
};
