import PropTypes from "prop-types";
import React from "react";

import { responsiveFontSizes, ThemeProvider, CssBaseline } from "@mui/material";
import mainTheme from "@product-site-frontend/shared/theme";

const main = responsiveFontSizes(mainTheme, { factor: 6 });

LayoutMainThemeProvider.propTypes = {
  children: PropTypes.node,
};
export default function LayoutMainThemeProvider({ children }) {
  return <ThemeProvider theme={main}><CssBaseline />{children}</ThemeProvider>;
}
