import PropTypes from "prop-types";
import React from "react";

import PageTechnicalWork from "../blocks/PageTechnicalWork";

import FooterContainer from "../components/FooterContainer";
import Layout from "./Layout";

LandingLayout.propTypes = {
  children: PropTypes.node,
};
export default function LandingLayout({ children }) {
  return (
    JSON.parse(process.env.GATSBY_ENGINEERING_WORKS) ? (
        <PageTechnicalWork />
    ) : (
      <Layout>
        {children}
        <FooterContainer />
      </Layout>
    )
  );
}
