import PropTypes from "prop-types";
import React, { useState } from "react";

import { DevTool } from "@hookform/devtools";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import useFetch from "use-http";

import AlertError from "../AlertError";
import { APPLICATION_FIELDS_CONFIG } from "./fields-config";
import { arenzaStorage } from "./FormApplication";
import { SmartCaptcha } from "@yandex/smart-captcha";

const PhoneComponent = APPLICATION_FIELDS_CONFIG.phone;

FormPhoneChange.propTypes = {
  application: PropTypes.object,
  put: PropTypes.func,
  setMode: PropTypes.func,
  setYandexCaptchaToken: PropTypes.func,
};
export default function FormPhoneChange({ application, setMode, setYandexCaptchaToken: propsSetYandexCaptchaToken}) {
  const [yandexCaptchaToken, setYandexCaptchaToken ] = useState('');

  const { clearErrors, control, formState, handleSubmit, setError } = useForm({
    mode: "onTouched",
  });

  const { loading, put, response } = useFetch(`/short_apps/${application.id}`, {
    cachePolicy: "no-cache",
  });

  const onHandlerYandexCaptcha = val => {
    if (val && val?.length) {
      propsSetYandexCaptchaToken(val)
      setYandexCaptchaToken(val)
    };
  }

  async function _onSubmit(data) {
    const result = await put(data);

    if (response.ok) {
      arenzaStorage.session("application", result);
      setMode("otp");
    } else {
      // TODO: Rollbar
      if (response.status === 400) {
        setError("phone", {
          type: "manual",
          message: result.error || "Неправильный формат номера",
          shouldFocus: true,
        });
      } else {
        setError("FORM_ERROR", {
          type: "manual",
          message: result.message || "Ошибка при отправке запроса. Попробуйте позднее.",
        });
      }
    }
  }

  return (
    <>
      <Typography mb={3} variant="h5">
        Укажите новый номер
      </Typography>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <Box sx={{ mb: 1 }}>
          <PhoneComponent.FieldComponent
            control={control}
            key={PhoneComponent.name}
            name={PhoneComponent.name}
            {...PhoneComponent.componentProps}
            disabled={loading}
            helperText=" "
            rules={{
              required: true,
              minLength: {
                value: 18,
                message: "Номер телефона должен состоять из 10 цифр",
              },
            }}
          />
        </Box>
        <AlertError
          message={formState.errors?.FORM_ERROR?.message}
          onClose={clearErrors}
          open={formState.errors?.FORM_ERROR ? true : false}
        />

        <Box sx={{ marginBottom: '24px' }}>
          <SmartCaptcha onSuccess={onHandlerYandexCaptcha} sitekey="ysc1_ukeyoNivym2HND6EMGFlIbLdbdmtpMHoOQ0MZ2CX092b1063" />
        </Box>

        <LoadingButton
          disabled={formState.errors?.FORM_ERROR || !yandexCaptchaToken?.length}
          fullWidth
          loading={loading}
          size="large"
          type="submit"
          variant="contained"
        >
          Изменить
        </LoadingButton>
      </form>
      <DevTool control={control} />
    </>
  );
}
